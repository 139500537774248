import "./ContinueReading.scss";

import Arrow from "../../assets/Arrow-horizontal.svg";
import { Link } from "gatsby";
import React from "react";

interface IProps {
  nextSection: string;
  link: string;
  onClick?: () => void;
}
export function ContinueReading(props: IProps) {
  const { nextSection, link } = props;
  const handleOnClick = () => {
    if (props.onClick) {
      props.onClick();
    }
  };
  return (
    <div className="continue">
      <h2 className="continue__title">continue reading</h2>
      {props.onClick ? (
        <div
          className="continue__next-section-name continue__next-section-name-button"
          onClick={() => handleOnClick()}
        >
          {nextSection}
          <div className="continue__arrow">
            <Arrow alt="next arrow" />
          </div>
        </div>
      ) : (
        <Link to={`/${link}/`} className="continue__next-section-name">
          {nextSection}
          <div className="continue__arrow">
            <Arrow alt="next arrow" />
          </div>
        </Link>
      )}
    </div>
  );
}
