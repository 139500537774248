import "./BlogPostContent.scss";
import { graphql } from "gatsby";
import React from "react";
import { MDXRenderer } from "gatsby-plugin-mdx";
import Seo from "../../Seo/seo";
import Cards from "./Cards/Cards";
import { ContinueReading } from "../../ContinueReading/ContinueReading";

function BlogPostContent({ data }) {
  const html = data.mdx.body;
  const posts = data.allMdx.edges;
  const {
    title,
    categories,
    authors,
    seoTitle,
    seoDescription,
    seoKeyWords,
    date,
  } = data.mdx.frontmatter;
  let cards = [];
  posts &&
    posts
      .filter((x) => x.title !== title)
      .map((x) => {
        cards.push({
          title: x.node.frontmatter.title,
          imageSource:
            x.node.frontmatter.imageSource?.childImageSharp.fluid.src,
          abstract: x.node.frontmatter.abstract,
          link: x.node.frontmatter.link,
        });
      });
  return (
    <>
      <div className="blog-post-content">
        <Seo
          title={seoTitle}
          description={seoDescription}
          keywords={seoKeyWords}
        />
        <h1>{title}</h1>
        <hr />
        <ul className="unordered-list unordered-list--dashed">
          {categories.map((category) => (
            <li key={category}>{category}</li>
          ))}
        </ul>
        <ul className="unordered-list unordered-list--dashed author-desktop">
          {authors.map((author) => (
            <li key={author}>{author}</li>
          ))}
        </ul>
        <div className="date-author-box">
          <div>
            {authors &&
              authors.map((author, index) => (
                <div className="author" key={author}>
                  {" "}
                  {author}{" "}
                </div>
              ))}
          </div>
          <div style={{ marginLeft: "auto" }}>
            {new Date(date).toLocaleDateString()}
          </div>
        </div>
        <div className="markdown-content">
          <div>
            <MDXRenderer>{html}</MDXRenderer>
          </div>
        </div>
      </div>
      <div className="sections">
        <section>
          <Cards cards={cards} />
          <ContinueReading nextSection="Contact" link="contact" />
        </section>
      </div>
    </>
  );
}
export default BlogPostContent;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 3
      filter: { frontmatter: { link: { ne: $slug } } }
    ) {
      edges {
        node {
          frontmatter {
            title
            link
            abstract
            date
            imageSource {
              childImageSharp {
                fluid(quality: 99) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    }
    mdx(frontmatter: { link: { eq: $slug } }) {
      id
      body
      frontmatter {
        title
        authors
        date
        link
        categories
        seoDescription
        seoTitle
        seoKeyWords
      }
    }
  }
`;
