import React from "react";
import "./Cards.scss";
import { Link } from "gatsby";
interface Props {
  cards: {
    title: string;
    abstract: string;
    imageSource: string;
    link: string;
  }[];
}

export default ({ cards }: Props) => {
  return (
    <div className="cards-container">
      {cards.map(({ title, abstract, imageSource, link }) => {
        return (
          <Link to={`/blog/${link}`} className="card">
            <div className="card__image">
              <img src={imageSource} alt={title} />
            </div>
            <div className="card__title">{title}</div>
            <div className="card__description">{abstract}</div>
          </Link>
        );
      })}
    </div>
  );
};
